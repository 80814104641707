import { Controller } from "stimulus";
import SlimSelect from "slim-select";
import 'slim-select/dist/slimselect.css';

export default class extends Controller {
  connect() {

    new SlimSelect({
      select: this.element,
      settings: {
        // Below are a list of optional fields
        // their values are the defaults
        disabled: false,
        alwaysOpen: false,
        showSearch: true,
        searchPlaceholder: 'بحث',
        searchText: 'لا توجد نتائج',
        searchingText: 'جاري البحث',
        searchHighlight: false,
        closeOnSelect: true,
        contentLocation: document.body,
        contentPosition: 'absolute',
        openPosition: 'auto', // options: auto, up, down
        placeholderText: 'Select Value',
        allowDeselect: false,
        hideSelected: true,
        showOptionTooltips: true,
        minSelected: 0,
        maxSelected: 1000,
        timeoutDelay: 200,
        maxValuesShown: 20,
        maxValuesMessage: '{number} selected',
      },
    })
  }
}
